<template>
  <main>
    <eden-page-header
      :title="'Vendors'"
      :subtitle="name"
      :section="'Edit Profile'"
    />
    <div v-loading="loading" class="eden-page-form">
      <el-form :model="form" label-position="top" ref="form">
        <eden-form-section>
          <template slot="form-fields">
            <el-row type="flex" :gutter="20" class="is-flex-wrap mb-0">
              <el-col :md="24" class="is-flex is-flex-column align-center">
                <eden-image-upload
                  :image-url.sync="form.image_url"
                  :image-folder="'customers'"
                />
              </el-col>
            </el-row>
          </template>
        </eden-form-section>
        <eden-form-section
          title="Vendor Details"
          subtitle="Let’s get to know this vendor"
        >
          <template slot="form-fields">
            <el-row type="flex" :gutter="20" class="is-flex-wrap">
              <el-col>
                <el-form-item
                  label="Business name"
                  prop="business_name"
                  :rules="validateName()"
                >
                  <el-input
                    type="text"
                    v-model="form.business_name"
                    placeholder="Enter business name"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="is-flex-wrap">
              <el-col :md="24">
                <el-form-item
                  label="Email"
                  prop="email"
                  :rules="validateEmail()"
                >
                  <el-input
                    type="text"
                    v-model="form.email"
                    placeholder="you@mail.com"
                    :disabled="!allowAccessFor(['admin', 'vendor_manager'])"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="is-flex-wrap">
              <el-col :md="24">
                <el-form-item
                  label="Business Phone number"
                  prop="phone_number"
                  :rules="validatePhone('Phone number')"
                >
                  <eden-phone-input :input.sync="form.phone_number" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="is-flex-wrap" ref="preferences">
              <el-col :md="24">
                <el-form-item label="Business bio" prop="business_bio">
                  <el-input
                    type="textarea"
                    rows="4"
                    v-model="form.business_bio"
                    placeholder="Enter the vendor’s business bio"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" class="is-flex-wrap" ref="preferences">
              <el-col :md="24">
                <el-form-item label="Business Address" prop="address">
                  <el-input type="text" v-model="form.address" id="streetRef" />
                  <span
                    v-if="
                      Object.values(canDeliverDetails).length &&
                      !canDeliver &&
                      form.longitude &&
                      form.latitude
                    "
                    class="text-danger font-sm"
                    >We currently don't operate in this location</span
                  >
                  <span
                    v-if="form.address && !form.location_area"
                    class="text-danger font-sm"
                    >Please update your business Address to indicate the city
                    you operate from</span
                  >
                </el-form-item>
              </el-col>
              <el-col :md="24">
                <el-form-item label="City" prop="city">
                  <el-input
                    disabled
                    type="text"
                    v-model="form.location_area"
                    ref="locationArea"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </template>
        </eden-form-section>
        <eden-form-section title="" subtitle="">
          <template slot="form-fields">
            <div class="eden-page-form__actions">
              <el-button plain @click="$router.go(-1)">Cancel</el-button>
              <el-button type="primary" :loading="updating" @click="update"
                >Save changes
              </el-button>
            </div>
          </template>
        </eden-form-section>
      </el-form>
    </div>
  </main>
</template>

<script>
import details from "@/requests/vendors/details.js";

export default {
  name: "VendorForm",
  data() {
    return {
      form: {
        image_url: this.getDefaultAvatarUrl(),
        business_name: "",
        email: "",
        business_bio: "",
        phone_number: "",
        address: "",
        latitude: 0.0,
        longitude: 0.0,
        location_area: "",
        location_area_id: null,
      },
      uploadingPhoto: false,
      vendorDetails: {},
      updating: false,
      loading: false,
      canDeliverDetails: {},
      LOCATION: localStorage.getItem("eden-crm-location"),
    };
  },
  computed: {
    vendorId() {
      return this.$route.params.id;
    },
    name() {
      return this.formatName(
        this.vendorDetails?.business_information?.business_name,
      );
    },
    canDeliver() {
      return this.canDeliverDetails.can_deliver;
    },
  },
  mounted() {
    const addressInput = document.getElementById("streetRef");
    const autocomplete = new window.google.maps.places.Autocomplete(
      addressInput,
      {
        componentRestrictions: {
          country: this.LOCATION === "NG" ? "ng" : "ke",
        },
      },
    );
    window.google.maps.event.addListener(autocomplete, "place_changed", () => {
      const place = autocomplete.getPlace();
      if (place && place.geometry && place.formatted_address && place.name) {
        this.form.address = place.name;
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        this.form.latitude = lat;
        this.form.longitude = lng;
        this.checkDelivery();
      }
    });
  },
  created() {
    this.getVendor();
  },
  methods: {
    async getVendor() {
      this.loading = true;
      await details
        .list(this.vendorId)
        .then((response) => {
          if (response.data.status) {
            this.vendorDetails = response.data.data;
            this.form = {
              image_url: this.vendorDetails?.business_information.image_url,
              business_name:
                this.vendorDetails?.business_information.business_name,
              email: this.vendorDetails?.vendor_account_creator.email_address,
              business_bio:
                this.vendorDetails?.business_information.business_bio,
              phone_number:
                this.vendorDetails?.business_information.business_phone_number.replace(
                  /^\+/,
                  "",
                ),
              address:
                this.vendorDetails?.business_information.business_address,
              location_area: this.vendorDetails?.location_area?.name,
              location_area_id: this.vendorDetails?.location_area?.id,
            };
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$message.error(err?.data.message);
        });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.updating = true;

        details
          .vendorDetails(this.vendorId, this.form)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.updating = false;
              this.$router.push({
                name: "vendors.details",
                params: { id: this.vendorId },
              });
              setTimeout(() => {
                this.$router.go(0);
              }, 500);
            }
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    checkDelivery() {
      const params = {
        longitude: this.form.longitude,
        latitude: this.form.latitude,
      };

      details
        .checkDelivery(params)
        .then((response) => {
          if (response.data.status) {
            this.canDeliverDetails = response.data.data;
            this.form.location_area =
              this.canDeliverDetails.details.closest_location.name;
            this.form.location_area_id =
              this.canDeliverDetails.details.closest_location.id;
          }
        })
        .catch((error) => {
          this.updating = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style scoped lang="scss"></style>
